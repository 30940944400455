@font-face {
    font-family: 'DIN Pro';
    src: url('./DINPro-Light.otf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('./DINPro.otf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('./DINPro-Medium.otf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('./DINPro-Bold.otf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('./DINPro-Bold.otf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('./DINPro-Black.otf');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    ascent-override: 95%;
}